<template>
  <div class=" ">
    <titleBar
      title="Image Creator"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="true"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="small"
      actionButtonRoundBg="transparent"
      iconPack="fas"
    />
    <ImagePopup ref="imagePopup" />

    <div class="section">
      <createTabs v-show="false" />

      <div class="container w1200" v-if="!loading">
        <div class="columns is-variable">
          <div class="column" style="padding-right: 30px">
            <div class="drop" style="display: flex; justify-content: center; align-items: center">
              <b-dropdown
                v-model="selectedBlueprintDropdown"
                @change="changeSelectedBlueprint"
                aria-role="list"
                style="max-width: 325px"
              >
                <template #trigger="{ active }">
                  <!--  
                <b-button
                  rounded
                  label="Add function"
                  :class="active ? 'arrow-up' : 'arrow-down'"
                  class="is-fullwidth"
                  icon-pack="fas"
                  type="is-dark"
                  icon-left="plus-circle"
                />-->

                  <div
                    class="blue-drop columns is-mobile is-variable is-1"
                    :class="active ? 'is-active' : 'not-active'"
                    style="cursor: pointer"
                  >
                    <div class="message-avatar column is-narrow" style="padding-bottom: 0; padding-top: 7px">
                      <img :src="blueprint?.image || bot.avatar" alt="" class="avatar" />
                    </div>

                    <div class="txt column" style="margin-left: 10px; padding: 0">
                      <h2 v-if="!blueprint || isDefaultBlueprint" class="title is-5" style="margin-bottom: 4px">
                        {{ bot.handle }}
                      </h2>
                      <span v-else>
                        <h2 class="title is-6 elip1" style="margin-bottom: 14px">
                          {{ bot.handle }}
                        </h2>

                        <h2 class="subtitle is-5 elip1" style="margin-bottom: 4px">
                          {{ blueprint.name }}
                        </h2>
                      </span>
                    </div>

                    <div
                      class="icon column is-narrow"
                      style="padding-top: 0; font-size: 20px; margin-left: 20px; margin-right: 5px"
                    >
                      <i class="far fa-chevron-down" style=""></i>
                    </div>
                  </div>

                  <!-- 
            dfsd {{ active }} - {{ selectedBlueprintDropdown }}
       
            <b-button
              :key="'button-' + index"
              :label="i.title + 666333331"
              type="is-primary"
              :icon-left="i.icon"
              icon-right="menu-down"
            /> -->
                </template>
                <!--
          <b-dropdown-item :value="'Featured'" aria-role="listitem">
            <div class="media">
              <b-icon class="media-left" icon="star"></b-icon>
              <div class="media-content">
                <h3>Featured</h3>
                <small>Featured items</small>
              </div>
            </div>
          </b-dropdown-item>  -->

                <b-dropdown-item
                  v-for="(i, index) in blueprintMenuItems"
                  :value="i.to || i.id"
                  :key="index"
                  aria-role="listitem"
                  :has-link="false"
                >
                  <div class="media">
                    <b-icon class="media-left" :icon="i.icon" pack="fal"></b-icon>
                    <div class="media-content">
                      <h3>{{ i.name }}</h3>
                      <small>{{ i.description }}</small>
                    </div>
                  </div>
                </b-dropdown-item>
                <hr class="dropdown-divider" />
                <b-dropdown-item value="/create" aria-role="listitem" :has-link="false">
                  <div class="media">
                    <b-icon class="media-left" icon="compass" pack="fal"></b-icon>
                    <div class="media-content">
                      <h3>Browse more...</h3>
                      <small>Explore all image generators</small>
                    </div>
                  </div>
                </b-dropdown-item>
              </b-dropdown>

              <DotDrop
                :items="[
                  {
                    t: `Edit  ${blueprint.name || blueprint.label} template `,

                    hidden: !isOwner,
                    icon: 'far fa-pencil',
                    to: `/bots/` + blueprint?.bot + '/' + blueprint?.id,
                  },
                  { t: 'Explore image generators', icon: 'far fa-compass', to: `/create` },
                  { t: blueprint.bot + ` profile`, icon: 'far fa-user-circle', to: `/${blueprint.bot}` },

                  //{ t: 'Search AI images', icon: 'far fa-search', to: `/search?q=&v=images` },
                  //   { t: 'Create posts for your bots', icon: 'far fa-user-friends', to: `/bots` },
                ]"
                :horizontal="true"
              />
            </div>

            <div class="intro-card" v-show="false">
              <div class="message-avatar">
                <router-link :to="`${bot.url}`">
                  <img :src="blueprint?.image || bot.avatar" alt="" class="avatar" />
                </router-link>
              </div>

              <jsonDebug :data="blueprint" label="blueprint" />
              <div class="message-handle">
                <h2 class="title is-6" style="margin-bottom: 4px">
                  {{ bot.handle }}
                  <span v-if="blueprint"> • {{ blueprint.name }} </span>
                </h2>
                <!--  
        <p class="subtitle is-6">{{ bot.bio }}</p>-->
                <router-link
                  v-if="isOwner"
                  :to="blueprint ? `/bots${bot.url}/${blueprint.id}` : `/bots${bot.url}`"
                  class="button is-small is-rounded"
                >
                  Edit settings
                </router-link>

                <!-- 
            <router-link
              :to="`/shared/convo/${bot.handle}/${convo?.publicConvoKey}`"
              class="button is-light is-small is-rounded"
             
            >
              Share
            </router-link> 

                <div v-if="!isOwner" class="button is-small is-rounded" @click="sharePublicLink">Share</div>
                -->
              </div>
            </div>

            <!-- 
            <h3 class="title is-3">
              Image Creator

              <DotDrop
                :items="[
                  { t: 'Explore templates', icon: 'far fa-drafting-compass', to: `explore` },

                  { t: 'Search AI images', icon: 'far fa-search', to: `/search?q=&v=images` },
                  { t: 'Create posts for your bots', icon: 'far fa-user-friends', to: `/bots` },
                ]"
              />
            </h3>

            AxggUuQPD
            {{ blueprint }}

            <templatePill :item="template" :closable="true" @clear="clearTemplate" style="margin-bottom: 10px" />


               <searchImagine :fullview="true" :templates="relatedBlueprints" :noDescription="false" :pageSize="9999" />

 -->

            <userImageGenForm
              ref="userImageGenForm"
              :hideOptionsButton="isDefaultBlueprint"
              :fullview="true"
              :blueprint="blueprint"
              @generateStart="generateStart"
              @generateDone="generateDone"
              :noDescription="false"
              :pageSize="9999"
              style="max-width: 600px; margin: auto; margin-top: 10vh"
            />
          </div>
        </div>
      </div>

      <div class="tabs is-centeredNO is-boxedNO">
        <ul>
          <li v-for="tab in bottomTabs" :key="tab.id" :class="{ 'is-active': isActive(tab.id) }">
            <a @click="selectTab(tab.id, true)">
              {{ tab.name }}
            </a>
          </li>
        </ul>
      </div>

      <!--
      {{ activeTabId }} ==activeTabId -->

      <div v-if="activeTabId == 'browseBlueprints'" class="browseBlueprints" key="tab43231">
        <div style="max-width: 1200px; margin: auto; padding-top: 20px">
          <h3 class="title is-5">Popular image generators</h3>

          <BlueprintList layout="image" :items="featuredBlueprints" />
        </div>
      </div>
      <div v-if="activeTabId == 'posts' && posts && posts.length" class=" " key="tab431">
        <vue-masonry-wall
          v-if="posts && posts.length"
          :items="posts.slice(0, 40)"
          :options="{
            width: postColWidth,
            padding: {
              default: 6,
              1: 2,
              2: 2,
              3: 3,
            },
          }"
        >
          <!--  @append="append"-->
          <template v-slot:default="{ item }">
            <div class="item" v-if="item && item.url">
              <router-link :to="item.url" v-if="item.url && item.featuredImage">
                <b-image
                  v-if="item.featuredImage && item.featuredImage.url"
                  :src="item.featuredImage.url300"
                  :placeholder="item.featuredImage.url50"
                  ratio="1by1"
                  :ratio-todo="item.featuredImage.ratio"
                ></b-image>
              </router-link>
              <h5>{{ item.lavel }}</h5>
              <p>{{ item.name }}</p>
            </div>
          </template>
          {{ posts }}ttttt
        </vue-masonry-wall>
      </div>

      <!-- 
      <div v-if="activeTabId == 'templates' && templates && templates.length" class="templates" key="tab4313534">
        <vue-masonry-wall :items="templates" :options="{ width: 300, padding: 12 }" @append="append">
          <template v-slot:default="{ item }">
            <router-link class="item box" :to="item.url">
              <div
                :key="item.index + 'tag' + index"
                @click="select(item.index)"
                ref="tags"
                class="round-tag"
                :class="{ 'selected-tag': item.isActive }"
                v-show="item.visible"
              >
                <img :src="item.image || item.avatar" alt="" class="avatar img" style="display: inline-block" />
                <span class="lab">{{ item.name }}</span>
              </div>
            </router-link>
          </template>
          {{ templates }}2222222
        </vue-masonry-wall>
      </div> -->

      <div v-if="activeTabId == 'history' && history && history.length" class="history-wrap" key="tab747777831">
        <div class="columns">
          <div class="column">
            <div v-if="selectedHistory" :key="selectedHistory.id + selectedHistory.tempId" class="item big-history">
              <!-- prompt and image under-->
              <div class="columns">
                <div class="column">
                  <!--  <h1 class="title is-5">
                    {{ selectedHistory.promptInput || selectedHistory.imagePrompt }}

                  
                    <b-button @click="reusePrompt(h)" type="is-small" outlined> reuse</b-button>
                    
                  </h1> -->

                  <div class="box" v-if="selectedHistory.moreInfoVisible">
                    <table class="table">
                      <tr>
                        <th>Template</th>
                        <td>
                          <span class="tag is-light">{{ selectedHistory.imagePromptTemplate || "None" }}</span>
                        </td>
                      </tr>
                      <tr>
                        <th>Prompt</th>
                        <td>
                          <span class="tag is-light">{{ selectedHistory.imagePrompt }}</span>
                        </td>
                      </tr>
                      <tr>
                        <th>Model</th>
                        <td>
                          <span class="tag is-danger">{{ selectedHistory.imageEngine }}</span>
                        </td>
                      </tr>
                      <tr>
                        <th>When</th>
                        <td>
                          <span class="tag is-light"
                            ><timeago :datetime="new Date(selectedHistory.created)" :auto-update="0"></timeago
                          ></span>
                        </td>
                      </tr>
                    </table>
                    <json-debug :data="h" />
                  </div>

                  <span class="tag is-warning" v-show="selectedHistory.status"> {{ selectedHistory.status }} </span>
                  <LoadingSpinner v-if="selectedHistory.status == 'pending'" />
                </div>
                <div class="column is-narrow">
                  <DotDrop :items="selectedHistory.menuItems" :horizontal="true" position="is-bottom-left" />
                </div>
              </div>

              <div
                class="grid-container"
                :class="{
                  one: selectedHistory.images.length == 1,
                  many: selectedHistory.images.length > 4,
                }"
              >
                <div
                  v-for="image in selectedHistory.images"
                  :key="image.url"
                  class="grid-item"
                  @click="showImagePopup(image.url)"
                >
                  <imgPlaceholder
                    :src="image.url"
                    placeholder="https://placehold.co/30x30"
                    :ratio="image.ratio"
                    :w="image.width"
                    :h="image.height"
                  />
                  <!-- 
                <img :src="image.url" loading="lazy " @click="showImagePopup(image.url)" /> -->
                </div>
              </div>
            </div>
            <jsonDebug :data="selectedHistory" />
            <!-- 
            BIIG generating == {{ generating }}
          

            <hr />
            {{ selectedHistoryId }} ==selectedHistoryId
            -->
          </div>
          <div class="history column is-narrow">
            <div v-if="history.length && !isDefaultBlueprint">
              <b-field>
                <b-switch v-model="onlyShowHistoryOfCurrentBot" size="is-small" type="is-dark">
                  {{ bot?.handle }} only

                  <!--
                    
                      Made with {{ bot?.handle }}:       {{ onlyShowHistoryOfCurrentBot }}

                  {{ blueprint?.label || blueprint?.name || blueprint?.id }}
                   -->
                </b-switch>
              </b-field>
            </div>
            <div class="history-scroll">
              <div
                v-for="h in history"
                :key="h.id + h.tempId"
                class="item"
                :class="{ selected: h.selected }"
                @click="clickHistoryItemInTray(h)"
              >
                <!-- prompt and image unde
                <div class="columns">
                  <div class="column">
                    <h1 class="title is-5">
                      {{ h.imagePrompt }}

                      <b-button @click="reusePrompt(h)" type="is-small" outlined> reuse</b-button>
                    </h1>

                    <div class="box" v-if="h.moreInfoVisible">
                      <table class="table">
                        <tr>
                          <th>Template</th>
                          <td>
                            <span class="tag is-light">{{ h.imagePromptTemplate || "None" }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th>Prompt</th>
                          <td>
                            <span class="tag is-light">{{ h.imagePrompt }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th>Model</th>
                          <td>
                            <span class="tag is-danger">{{ h.imageEngine }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th>When</th>
                          <td>
                            <span class="tag is-light"
                              ><timeago :datetime="new Date(h.created)" :auto-update="0"></timeago
                            ></span>
                          </td>
                        </tr>
                      </table>
                      <json-debug :data="h" />
                    </div>

                    <span class="tag is-warning" v-show="h.status"> {{ h.status }} </span>
                    <LoadingSpinner v-if="h.status == 'pending'" />
                  </div>
                  <div class="column is-narrow"><DotDrop :items="h.menuItems" position="is-bottom-left" /></div>
                </div>

                r-->

                <div
                  class="grid-container"
                  :class="{
                    one: h.images.length == 1,
                    many: h.images.length > 4,
                  }"
                >
                  <div v-for="image in h.images" :key="image.url" class="grid-item">
                    <imgPlaceholder
                      :src="image.url"
                      placeholder="https://placehold.co/30x30"
                      :ratio="image.ratio"
                      :w="image.width"
                      :h="image.height"
                    />
                    <!-- 
                <img :src="image.url" loading="lazy " @click="showImagePopup(image.url)" /> -->
                  </div>
                </div>
              </div>
            </div>
            <!-- 
                 <div class="images">
              <figure class="image" v-for="i in h.images" :key="i.url">
                <img :src="i.url" alt="Post Image" />
              </figure>
            </div>
            
          <figure class="image">
            <img :src="imageResult" alt="Result" />
          </figure> -->
            <json-debug :data="history" />
          </div>
        </div>

        <vue-masonry-wall :items="templates" :options="{ width: 300, padding: 12 }" @append="append">
          <template v-slot:default="{ item }">
            <div class="item box">
              aaa3333
              <h5>{{ item.lavel }}</h5>
              <p>{{ item.name }}</p>
            </div>
          </template>
        </vue-masonry-wall>
      </div>

      <div class="container">
        <br />

        <!--  
            
            :actions="mobileActions"WORKING TABS 
            
      <section>
        <b-tabs v-model="activeTab" type="is-boxed" @input="navigate">
          <b-tab-item v-for="(tab, index) in tabs" :key="index" :label="tab.label"> </b-tab-item>
        </b-tabs>
      </section>


        <empty
          v-if="!items.length && !loading"
          title="Save posts for later"
          sub="Don't let the good ones fly away! Bookmark posts to easily find them again in the future."
          img="https://cdn1.onlybots.cc/bots/OnlyBots.assets/posts/Jiku4-wAY/d9ko24iOPT____e99be2c4-1ec6-4ed6-8351-7b8c43397194.jpeg"
        />      -->

        <br />
        <br />
        <div class=" " v-if="!items.length && !loading"></div>
      </div>
    </div>
  </div>
</template>

<script>
//import postList
import postList from "@/components/PostList.vue";
import DotDrop from "@/components/e/DotDrop.vue";
import createTabs from "@/components/nav/createTabs.vue";

import NewPostTeaser from "@/components/studio/NewPostTeaser.vue";
import imgPlaceholder from "@/components/e/imgPlaceholder.vue";
import userImageGenForm from "@/components/generate/userImageGenForm.vue";

import templatePill from "@/components/e/templatePill.vue";
import ImagePopup from "@/components/studio/imagePopup.vue";
import VueMasonryWall from "vue-masonry-wall";
import BlueprintList from "@/components/BlueprintList.vue";
import JsonDebug from "../components/e/jsonDebug.vue";

const RANDOM_ICON = "https://cdn-icons-png.flaticon.com/512/8291/8291622.png";
// "https://static-00.iconduck.com/assets.00/perspective-dice-six-faces-random-icon-2048x2048-9uct7e7b.png";
// "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVurw5YqbQPK5voh20qJ2hgL7CSXcmaig-3xP0yNZaoIfqEDSs7T8InJqME6sh7tuaqhs&usqp=CAU";

const DEFAULT_BLUEPRINT = {
  bot: "auto",
  id: "random-image",
  name: "auto",
  image: RANDOM_ICON,
  isDefault: true,
};

export default {
  data() {
    return {
      historyData: [],
      selectedHistoryId: null,
      items: [],
      bot: {},
      generating: false,
      onlyShowHistoryOfCurrentBot: false,
      selectedBlueprintDropdown: null,
      relatedBlueprints: [], //drop
      featuredBlueprints: [], //explore
      blueprint: DEFAULT_BLUEPRINT, //null,
      activeTab: 0, // Index of the active tab
      loading: true,
      tabs: [
        { route: "/following", label: "Following" },
        { route: "/latest", label: "Latest" },
        { route: "/trending", label: "Trending" },
        // Add more tabs here
      ],

      activeTabId: null, //manage on mounted... "posts", //this.$route.query ? "preview" : "posts", // Default to first tab

      //  history: [],
      posts: [],
      postColWidth: (window.innerWidth / 6 + 200) / 2 || 200,
    };
  },
  components: {
    // postList,
    createTabs,
    VueMasonryWall,
    // templatePill,
    userImageGenForm,
    imgPlaceholder,
    DotDrop,
    ImagePopup,
    BlueprintList,
    JsonDebug,
    //  DotDrop,
    // NewPostTeaser,
  },
  computed: {
    blueprintMenuItems() {
      /*
      var aa = [
        {
          id: "image",
          title: "Image",
          description: "Create an image",
          icon: "far fa-image",
        },
        {
          id: "message",
          title: "Message",
          description: "Create a message",
          icon: "far fa-comment",
        },
        {
          id: "video",
          title: "Video",
          description: "Create a video",
          icon: "far fa-video",
        },
        {
          id: "audio",
          title: "Audio",
          description: "Create an audio",
          icon: "far fa-music",
        },
        {
          id: "gif",
          title: "Gif",
          description: "Create a gif",
          icon: "far fa-images",
        },
        {
          id: "story",
          title: "Story",
          description: "Create a story",
          icon: "far fa-images",
        },
        {
          id: "post",
          title: "Post",
          description: "Create a post",
          icon: "far fa-images",
        },
        {
          id: "template",
          title: "Template",
          description: "Create a template",
          icon: "far fa-images",
        },
      ];*/

      var def = this.isDefaultBlueprint;
      let aa;
      if (def) {
        aa = this.featuredBlueprints;
      } else {
        aa = this.relatedBlueprints;
      }

      aa = aa.map((i) => {
        var u = `/images/${i.bot}?template=` + i.id;
        i.to = u; //`/images/${this.bot.handle}/${i.id}`;

        return i;
      });
      return aa;
    },

    selectedTab() {
      return this.bottomTabs.find((tab) => tab.id === this.activeTabId);
    },
    bottomTabs() {
      /*
           //  { id: "preview", name: "Template preview", visible: this.template },
        { id: "posts", name: "Explore ideas", content: "Explore ideas content here..." },
        //  { id: "templates", name: "Templates", content: "Templates content here..." },
        { id: "history", name: "Creations", content: "Creations content here..." },
        //  { id: "help", name: "Help", icon: "Help" },*/

      var def = this.isDefaultBlueprint;
      let tabs;
      if (def) {
        tabs = [
          //  { id: "preview", name: "Template preview", visible: this.template },
          { id: "browseBlueprints", name: "Explore ideas", content: "Explore ideas content here..." },
          //  { id: "templates", name: "Templates", content: "Templates content here..." },
          { id: "history", name: "Creations", content: "Creations content here..." },
          //  { id: "help", name: "Help", icon: "Help" },
        ];
      } else {
        var name = this.blueprint.name || this.blueprint.label;
        var bot = this.blueprint.bot;

        // check if the template name contains the bot name, lowercase compare
        var redundantNaming = name.toLowerCase().includes(bot.toLowerCase());
        var tbname = redundantNaming ? name : name + " (" + bot + ")";
        //  else tbname = name + " (" + bot + ")";
        tabs = [
          // A style is sxelected.

          //  { id: "preview", name: "Template preview", visible: this.template },
          { id: "posts", name: tbname },
          { id: "browseBlueprints", name: "Browse styles" },
          //  { id: "templates", name: "Templates", content: "Templates content here..." },
          { id: "history", name: "Creations" },
          //  { id: "help", name: "Help", icon: "Help" },
        ];
      }

      return tabs;
    },
    isDefaultBlueprint() {
      var b = this.$route.params.bot;
      var newPaths = "default new explore".split(" ");
      return newPaths.includes(b);
    },
    isOwner() {
      var currentUser = this.$store.main.state.userId;
      if (!currentUser) return false;
      if (!this.bot.owner) return false;
      return this.bot.owner.includes(currentUser);
    },
    history() {
      var fullHistory = this.historyData.map((h) => {
        return {
          ...h,
          selected: h.id === this.selectedHistoryId || h.tempId === this.selectedHistoryId || false,
          menuItems: [
            {
              t: "Delete",
              icon: "far fa-trash",
              // hidden: !this.isOwner,
              click: () => {
                // alert(324235);

                this.$buefy.dialog.confirm({
                  message: "Delete this post?",
                  type: "is-danger",
                  onConfirm: () => {
                    window.API.editBotPost(this.botId, h.id, { deleted: true }).then((res) => {
                      this.$buefy.snackbar.open({
                        message: "Post deleted",
                        type: "is-warning",
                        position: "is-bottom",
                        actionText: "Close",
                        indefinite: false,
                      });
                      this.item = null;
                    });

                    //this.$buefy.toast.open('User confirmed')
                  },
                });
              },
            },
            {
              t: this.draft ? "Publish" : "Edit...",
              icon: "far fa-edit",
              //hidden: !this.isOwner,
              to: h.url + "/edit",
            },
            //view post
            {
              t: "View post",
              icon: "far fa-eye",
              to: h.url,
              hidden: this.draft,
            },

            // view more info button, toggle this for the entry  moreInfoVisible
            {
              t: "More info",
              icon: "far fa-info",
              click: () => {
                // h.moreInfoVisible = true;
                this.$set(h, "moreInfoVisible", true);
              },
            },

            {
              t: "Download images",
              icon: "far fa-download",
              click: () => {
                this.downloadImages(h.images);
              },
            },
          ],
          images: h.images.map((i) => {
            var ratio = i.ratio || i.w / i.h || 1;
            return {
              ...i,
              ratio: ratio,
            };
          }),
        };
      });

      if (this.onlyShowHistoryOfCurrentBot && !this.isDefaultBlueprint) {
        fullHistory = fullHistory.filter((h) => h.bot == this.bot.handle);
      }

      return fullHistory;
    },

    selectedHistory() {
      //this.selectedHistoryId = "f3xXyldP6";
      return this.history.find((h) => h.id === this.selectedHistoryId);
    },
  },
  methods: {
    load() {
      //   const response = await fetch("/api/bots");
      //   const botTemplates = await window.API.me.getBotBlueprint();

      //this.items = data;
      var params = this.$route.query;
      var bot = this.$route.params.bot;
      var template = params.template || "default";
      var q = params.q;
      if (bot && template) {
        if (this.isDefaultBlueprint) {
          this.blueprint = DEFAULT_BLUEPRINT;
          this.loading = false;
        } else {
          window.API.getBotBlueprint(bot, template).then((res) => {
            this.blueprint = res;
            this.loading = false;
          });
        }

        window.API.getRelatedBotBlueprints(bot, template, { type: "image" }).then((proj) => {
          this.relatedBlueprints = proj;
          this.loadingRelated = false;
          // console.log("PROJJ!", proj, proj.id);
        });
        window.API.getBotBlueprintPosts(bot, template).then((data) => {
          this.posts = data;
        });

        /*
        window.API.getSuggestedBluerprints({ bot }).then((res) => {
          this.templates = res;
        });*/
      }

      //TODO, change to a related FN for that template...
      window.API.getGlobalPostFeed({ limit: 30 }).then((data) => {
        this.globalFeatured = data;
      });
    },
    loadBot() {
      if (!this.isDefaultBlueprint) {
        var b = this.$route.params.bot;
        window.API.botProfile(b).then((res) => {
          this.bot = res;
        });
      } else {
        this.bot = { handle: "Random style", avatar: RANDOM_ICON };
      }
    },
    loadHistory() {
      // Logic for loading the history
      window.API.me.getUserImageJobs().then((res) => {
        console.log(">>getBotImageJobs res >> ", res);
        this.historyData = res;
      });
    },
    loadExploreBlueprints() {
      window.API.getSuggestedBlueprints({ type: "image" }).then((res) => {
        this.featuredBlueprints = res;

        // console.log("PROJJ!", proj, proj.id);
      });
    },

    changeSelectedBlueprint(t) {
      console.log(t);
      //  alert(88877);

      this.$router.push(t);
      this.selectTab("posts"); //switch to posts samples when selecting from dropdown
    },
    clearTemplate() {
      this.bot = null;
      this.blueprint = null;
    },
    append() {
      //method called when we append stuff to mosaic
    },
    selectTab(id, selectFirstItem) {
      this.activeTabId = id;
      if (selectFirstItem && this.historyData.length) {
        this.selectedHistoryId = this.historyData[0].id;
      }
      if (this.activeTabId == "preview") this.activeTabId = "posts";
    },
    isActive(id) {
      return this.activeTabId === id;
    },
    getBotBlueprint(post) {
      //   alert("Bookmark removed" + post);
      //this.template = await window.API.me.getBotBlueprint();
    },
    /*
    navigate(newIndex) {
      //  this.$router.push(this.tabs[newIndex].route);
    },*/
    truncate(text2, length) {
      if (!text2) return;
      var text = String(text2);
      if (text.length > length) {
        return String(text).substring(0, length) + "...";
      }
      return text;
    },
    viewBot(bot) {
      // Code to view bot details
    },
    manageBot(bot) {
      // Code to manage bot settings
    },
    generateStart(newJob) {
      this.generating = true;
      this.historyData.unshift(newJob);
      this.selectedHistoryId = newJob.tempId;
      this.selectTab("history");
    },
    generateDone(tempId, post) {
      this.generating = false;
      this.replaceHistoryItemByTempId(tempId, post);
    },
    replaceHistoryItemByTempId(tempId, newData) {
      const itemIndex = this.historyData.findIndex((item) => item.tempId === tempId);

      console.log(newData, "newData");
      if (itemIndex !== -1) {
        newData.status = "loaded";
        //  this.historyData[itemIndex] = newData;
        this.historyData.splice(itemIndex, 1, newData);
        //    alert(itemIndex);
        /*
        .splice(itemIndex, 1, {
          ...newData,
          status: "loaded",
        });*/
      }
      //if selected id was previous tempid, update it to new id
      if (this.selectedHistoryId === tempId) {
        this.selectedHistoryId = newData.id;
      }
    },
    clickHistoryItemInTray(item) {
      var id = item.id || item.tempId;
      this.selectedHistoryId = id;
      var prompt = "cat";
      if (item.imagePrompt) prompt = item.imagePrompt;
      if (item.promptInput) prompt = item.promptInput;
      // set prompt on input
      prompt = String(prompt).trim();
      this.$refs.userImageGenForm.setPrompt(prompt);

      //ok, also set the blueprint template on the dropdown
      var blueprintId = item.blueprint; //the id
      //change the route using router replace
      var route = `/images/${item.bot}?template=${blueprintId}`;
      this.$router.replace(route);
    },

    showImagePopup(src) {
      this.$refs.imagePopup.open(src);
    },
  },
  mounted() {
    var def = this.isDefaultBlueprint;
    this.load();
    this.loadBot();
    this.loadHistory();
    this.loadExploreBlueprints();

    this.activeTabId = def ? "browseBlueprints" : "posts";
    //manage on mounted... "posts", //this.$route.query ? "preview" : "posts", // Default to first tab

    const tabIndex = this.tabs.findIndex((tab) => tab.route === this.$route.path);
    this.activeTab = tabIndex === -1 ? 0 : tabIndex;
  },
  watch: {
    "$route.path"() {
      const tabIndex = this.tabs.findIndex((tab) => tab.route === this.$route.path);
      this.activeTab = tabIndex === -1 ? 0 : tabIndex;
      this.load();
    },
    "$route.query.template"() {
      var def = this.isDefaultBlueprint;
      //// console.log(543543);
      this.load();
      this.posts = [];
      if (this.activeTabId != "history" || this.$route.params.bot == "new") {
        if (def) {
          this.activeTabId = "browseBlueprints"; //switch to posts samples when selecting from dropdown AND inline list in explore tab
        } else {
          this.activeTabId = "posts"; //switch to posts samples when selecting from dropdown AND inline list in explore tab
        }
      }
    },
    "$route.params.bot"() {
      //// console.log(543543);
      this.loadBot();
      this.load();
      this.posts = [];
      this.onlyShowHistoryOfCurrentBot = false; //reset checkbox when changing template, buggy with empty state. better ux.
    },
  },
};
</script>

<style scoped>
.elip1 {
  /* 2 lines max elipsis */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.blue-drop {
  padding: 0 4px;
  padding-right: 11px;
  background: #fff;
  border-radius: 24px;
  display: flex;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.blue-drop img.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: auto;
}

.intro-card {
  text-align: center;
}
img.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: auto;
}
.botTable .description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.botTable td {
  vertical-align: middle;
}

.images {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-content: space-between;
}
.images .image {
  margin-right: 5px;
}

/* img grid */
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2px; /* Adjust this value for spacing between images */
}
.grid-container.one {
  grid-template-columns: repeat(1, 1fr);
}
.grid-container.many {
  grid-template-columns: repeat(3, 1fr);
}

.grid-item img {
  width: 100%;
  height: auto;
  display: block;
}
.grid-container >>> img {
  border-radius: 3px;
}

.history .item {
  border: 3px solid #fff;
  padding: 3px;
  border-radius: 8px;
  min-width: 150px;
}

.history .item.selected {
  border-color: green;
}

.history {
  background: #fff;
  padding: 20px;
}
.history-scroll {
  width: 100vw;

  height: 300px; /* TODO  */
  overflow: scroll;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
}

/* on desktop larger */
@media (min-width: 769px) {
  .history-scroll {
    width: 160px;
    height: 600px; /* TODO  */

    flex-direction: column;
    overflow: scroll;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .history .item {
    min-width: auto;
    margin-bottom: 3px;
  }
}

.big-history {
  max-width: 50vh;
  margin: auto;
  display: block;
}

.big-history .grid-container {
  grid-gap: 10px; /* Adjust this value for spacing between images */
}
.big-history .grid-container >>> img {
  border-radius: 6px;
}
</style>

<template>
  <div class=" ">
    <!-- Include the ImagePopup component -->
    <ImagePopup ref="imagePopup" />

    <!-- 
            {{ blueprint }}

    <roundTagRow
      :item="templates"
      :selectedIndex="selectedTemplateIndex"
      @select="(i) => (selectedTemplateIndex = i)"
    />-->

    <div class="boxy-bg" style="margin-top: 20px">
      <!-- Column 1 
      <div class="column is-3">
        <div class="list is-hoverable">
          <a class="list-item" v-for="(template, index) in templates" :key="index" @click="selectedTemplate = template">
            <figure class="image is-48x48">
              <img :src="template.avatar" alt="" />
            </figure>
            {{ template.name }}
          </a>
        </div>
      </div>-->
      <!-- Column 2 -->
      <div class="columns is-mobile">
        <div class="column stickyForm-NO" style="padding: 0">
          <div class="field">
            <div class="control">
              <textarea-autosize
                :min-height="10"
                :max-height="250"
                required=""
                :placeholder="promptPlaceholder"
                class="input area"
                v-model="promptInput"
                name="commentText"
                style="border-radius: 10px; background: #fafafa; margin-bottom: 10px"
              ></textarea-autosize>

              <!--  
              <textarea class="area textarea input" v-model="promptInput" :placeholder="promptPlaceholder"></textarea>
              -->
            </div>
          </div>
        </div>

        <div class="column is-narrow row">
          <div class="left">
            <!--   <div class="field"></div>

              <b-dropdown v-model="promptMode" aria-role="list">
                <template #trigger>
                  <b-button icon-pack="far" type="is-text" rounded :key="promptMode + 'Fsd'">
                    <i :class="currentpromptMode.icon" aria-hidden="true"></i>
                  </b-button>
                </template>

                <b-dropdown-item
                  v-for="stat in promptModeOptions"
                  :key="stat.value + 99998"
                  :value="stat.value"
                  aria-role="listitem"
                >
                  <div class="media">
                    <i :class="stat.icon" aria-hidden="true"></i>
                    <div class="media-content">
                      <h3>{{ stat.label }}</h3>
                    </div>
                  </div>
                </b-dropdown-item>
              </b-dropdown>

               -->
          </div>

          <div class="control right">
            <div>
              <b-button
                @click="showAdvanced = !showAdvanced"
                v-show="hideOptionsButton == false"
                icon-pack="far"
                type="is-text"
                rounded
                :class="{
                  'is-active': showAdvanced,
                }"
              >
                <i class="fas fa-sliders-h" aria-hidden="true"></i>
              </b-button>

              <b-button
                class="button is-dark"
                icon-lib="fas"
                @click="generate"
                :disabled="!canSubmit"
                :loading="generating"
                style="padding: 0px 20px"
              >
                Generate
              </b-button>
              <!--   icon-right="arrow-right" -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Column 3 -->

    <div class="box advanced" v-show="showAdvanced">
      <!-- Models Dropdown -->
      <h3 class="title is-5"><i class="far fa-sliders-h"></i> Advanced options</h3>

      <br />

      <b-field label="" class="is-flex is-justify-content-space-between">
        <b-slider
          v-model="ratioIndex"
          :custom-formatter="(val) => ratios[val].label"
          :min="0"
          :max="8"
          :step="1"
          type="is-dark"
          ticks
        >
          <b-slider-tick v-for="(r, i) in ratios" :key="i + 'fds' + r.label" :value="i" :label="r.label">
            {{ r.label }}
          </b-slider-tick>
        </b-slider>
      </b-field>
      <br />
      <br />

      <b-field label="Model" class="has-label-fixed">
        <b-select v-model="selectedModel">
          <option v-for="(model, index) in models" :key="model.id + index" :value="model.id" :disabled="model.disabled">
            <span> <i :class="model.icon"></i> {{ model.label }} </span>
          </option>
        </b-select>
      </b-field>

      <div class="sd-controls" v-if="selectedModel.startsWith('sd')">
        <!-- Guidance input -->
        <b-field label="Guidance" class="is-flex is-justify-content-space-between">
          <b-input type="number" min="1" max="30" step="1" v-model="guidance"></b-input>
          <b-slider v-model="guidance" :min="1" :max="30" :step="1"></b-slider>
        </b-field>

        <!-- Quality input -->
        <b-field label="Quality (steps)" class="is-flex is-justify-content-space-between">
          <b-input type="number" min="10" max="150" v-model="steps"></b-input>
          <b-slider v-model="steps" :min="10" :max="150" :step="1"></b-slider>
        </b-field>

        <!-- Schedulers Dropdown -->
        <b-field label="Scheduler" class="has-label-fixed">
          <b-select v-model="selectedScheduler">
            <option v-for="schedulers in schedulers" :key="schedulers.id" :value="schedulers.id">
              <span> <i :class="schedulers.icon"></i> {{ schedulers.label || schedulers.id }} </span>
            </option>
          </b-select>
        </b-field>

        <div class="field">
          <label class="label">
            <span>Negative prompt </span>
          </label>
          <div class="control">
            <textarea
              class="textarea"
              v-model="netativePrompt"
              placeholder="Ugly, watermark, low quality, blurry, low resolution, low res "
            ></textarea>
          </div>
        </div>
      </div>

      <!-- Seed input -->
      <b-field label="Seed" v-show="selectedModel != 'dalle'">
        <b-input type="number" placeholder="Random"></b-input>
      </b-field>
    </div>

    <br />
    <br />
    <br />

    <json-debug :data="options" label="options" />
    <json-debug :data="blueprint" label="blueprint" />
  </div>
</template>

<script>
import jsonDebug from "../e/jsonDebug.vue";
import DotDrop from "@/components/e/DotDrop.vue";

import ImagePopup from "@/components/studio/imagePopup.vue";

import LoadingSpinner from "@/components/e/LoadingSpinner.vue";
import imgPlaceholder from "@/components/e/imgPlaceholder.vue";
import roundTagRow from "@/components/nav/roundTagRow.vue";

import templatePill from "@/components/e/templatePill.vue";

var DUMMY_HISTORY = [
  {
    date: "2021-05-01",
    prompt: "A lion in the savannah",
    negativePrompt: "Ugly, watermark, low quality, blurry, low resolution, low res ",
    images: [
      {
        url: "https://via.placeholder.com/600",
        ratio: 1.5,
      },
      {
        url: "https://via.placeholder.com/600x333",
        ratio: 1.5,
      },
      {
        url: "https://via.placeholder.com/600x222",
        ratio: 1.5,
      },
      {
        url: "https://via.placeholder.com/600x111",
        ratio: 1.5,
      },
    ],
  },
];

export default {
  components: {
    //

    // DotDrop,
    ImagePopup,
    //  LoadingSpinner,
    //  imgPlaceholder,
    //  roundTagRow,
    // templatePill,
  },
  props: {
    templates: {
      type: Array,
      default: () => [
        { name: "Ugly painting", image: "https://placekitten.com/48/55", bot: "Frida" },
        { name: "Animal portrait 1", image: "https://placekitten.com/200/300", bot: "Frida" },
        //    { name: "Animal portrait 2", image: "https://placekitten.com/250/350", bot: "Frida" },
        { name: "Landscape art", image: "https://placekitten.com/300/450", bot: "Frida" },
        { name: "Abstract painting", image: "https://placekitten.com/320/480", bot: "Frida" },
        { name: "Modern art", image: "https://placekitten.com/400/550", bot: "Frida" },
        { name: "Still life", image: "https://placekitten.com/420/600", bot: "Frida" },
        { name: "Classical portrait", image: "https://placekitten.com/350/500", bot: "Frida" },
        { name: "Minimalist design", image: "https://placekitten.com/380/520", bot: "Frida" },
        { name: "Surrealist painting", image: "https://placekitten.com/450/650", bot: "Frida" },
      ],
    },
    expandTemplatesOnLoad: {
      type: Boolean,
      default: false,
    },
    fullview: {
      type: Boolean,
      default: false,
    },
    blueprint: {
      type: Object,
      required: true,
    },
    hideOptionsButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expandTemplates: this.expandTemplatesOnLoad,
      selectedTemplateIndex: null, //first one default
      historyData: DUMMY_HISTORY,
      generating: false,
      //  selectedTemplate: null,
      promptInput: "",
      netativePrompt: "",
      imageResult: "", // update this when "generate" button is clicked
      showAdvanced: false,
      nbImages: 4,
      isDraft: false,
      selectedModel: "sdxl",
      models: [
        { id: "sdxl", label: "SD XL", icon: "fa fa-truck" },
        { id: "sd", label: "SD", icon: "fa fa-truck" },
        { id: "dalle", label: "Dalle", icon: "fa fa-car" },

        { id: "mj", label: "Midjourney", icon: "fa fa-truck", disabled: false },
        // Other models...
      ],
      schedulers: [
        { id: "auto", label: "Auto", icon: "fa fa-code" }, // KarrasDPM is best
        { id: "DDIM", icon: "fa fa-code" },
        { id: "DPMSolverMultistep", icon: "fa fa-code" },
        { id: "HeunDiscrete", icon: "fa fa-code" },
        { id: "KarrasDPM", icon: "fa fa-code" },
        { id: "K_EULER_ANCESTRAL", icon: "fa fa-code" },
        { id: "K_EULER", icon: "fa fa-code" },
        { id: "PNDM", icon: "fa fa-code" },
      ],
      selectedScheduler: "auto", // "KarrasDPM",
      postVisibility: "public",
      postVisibilityOptions: [
        { label: "Public post", value: "public", icon: "fas fa-eye" },
        { label: "Private draft", value: "draft", icon: "fas fa-lock" },
      ],

      promptMode: "basic",
      promptModeOptions: [
        { label: "Full prompt", value: "basic", icon: "fas fa-pencil" },
        { label: "Prefixed prompt", value: "prefix", icon: "fas fa-puzzle-piece" },
        { label: "Expand with GPT ", value: "remix", icon: "fas fa-flask" },
      ],

      ratiosData: [
        {
          w: 21,
          h: 9,
        },
        {
          w: 16,
          h: 9,
        },
        {
          w: 3,
          h: 2,
        },
        {
          w: 5,
          h: 4,
        },
        {
          w: 1,
          h: 1,
        },
        {
          w: 4,
          h: 5,
        },
        {
          w: 2,
          h: 3,
        },
        {
          w: 9,
          h: 16,
        },
        {
          w: 9,
          h: 21,
        },
      ],
      ratioIndex: 4,

      //SD stuff
      steps: 40,
      guidance: 50,
    };
  },
  created() {
    // Select the "No template" by default
    // this.selectedTemplate = this.templates[0];
  },
  computed: {
    selectedTemplate() {
      var index = this.selectedTemplateIndex;
      if (index === null) {
        return null;
      }
      return this.templates[index];
    },
    botId() {
      return this.$route.params.bot;
    },
    prompt() {
      return "NA NA FOR USERS - prompt";
      /*
      var mode = this.promptMode;
      if (mode == "prefix") {
        return this.prefix + ", " + this.promptInput;
      } else if (mode == "remix") {
        return this.promptInput;
      } else {
        return this.promptInput;
      }*/
    },
    canSubmit() {
      if (this.promptInput.length < 2) {
        return false;
      }
      return true;
    },
    hasATemplateSelected() {
      return this.selectedTemplate !== null;
    },
    selectedTemplateName() {
      return this.selectedTemplate.name;
    },
    currentPostVisibility() {
      return this.postVisibilityOptions.find((o) => o.value === this.postVisibility);
    },
    currentpromptMode() {
      return this.promptModeOptions.find((o) => o.value === this.promptMode);
    },
    promptPlaceholder() {
      const DEFAULT_PROMPT = "An angry turtle";
      return this.blueprint?.placeholder || DEFAULT_PROMPT;

      /*
      if (this.promptMode == "prefix") {
        return "A lion in the savannah";
      } else if (this.promptMode == "remix") {
        return "An angry turtle ";
      } else {
        return "A gentle robot farming in the countryside";
      }*/
    },
    ratios() {
      return this.ratiosData.map((r, i) => {
        return {
          ...r,
          label: `${r.w}:${r.h}`,
          value: i,
          ratio: r.w / r.h,
        };
      });
    },
    ratio() {
      return this.ratios[this.ratioIndex];
    },
    history() {
      return this.historyData.map((h) => {
        return {
          ...h,
          menuItems: [
            {
              t: "Delete",
              icon: "far fa-trash",
              // hidden: !this.isOwner,
              click: () => {
                // alert(324235);

                this.$buefy.dialog.confirm({
                  message: "Delete this post?",
                  type: "is-danger",
                  onConfirm: () => {
                    window.API.editBotPost(this.botId, h.id, { deleted: true }).then((res) => {
                      this.$buefy.snackbar.open({
                        message: "Post deleted",
                        type: "is-warning",
                        position: "is-bottom",
                        actionText: "Close",
                        indefinite: false,
                      });
                      this.item = null;
                    });

                    //this.$buefy.toast.open('User confirmed')
                  },
                });
              },
            },
            {
              t: this.draft ? "Publish" : "Edit...",
              icon: "far fa-edit",
              //hidden: !this.isOwner,
              to: h.url + "/edit",
            },
            //view post
            {
              t: "View post",
              icon: "far fa-eye",
              to: h.url,
              hidden: this.draft,
            },

            // view more info button, toggle this for the entry  moreInfoVisible
            {
              t: "More info",
              icon: "far fa-info",
              click: () => {
                // h.moreInfoVisible = true;
                this.$set(h, "moreInfoVisible", true);
              },
            },

            {
              t: "Download images",
              icon: "far fa-download",
              click: () => {
                this.downloadImages(h.images);
              },
            },
          ],
          images: h.images.map((i) => {
            var ratio = i.ratio || i.w / i.h || 1;
            return {
              ...i,
              ratio: ratio,
            };
          }),
        };
      });
    },
    options() {
      return {
        nbImages: this.nbImages,
        promptInput: this.promptInput,
        negativePrompt: this.netativePrompt,
        model: this.selectedModel,
        scheduler: this.selectedScheduler,
        seed: this.seed,
        guidance: this.guidance,
        steps: this.steps,
        // quality: this.quality,
        //  postVisibility: this.postVisibility,,
        draft: this.postVisibility == "private",
        created: new Date(),
        //   promptMode: this.promptMode,
      };
    },
  },
  mounted() {
    this.loadHistory();
  },

  methods: {
    generate() {
      // Logic for generating the result based on selectedTemplate and prompt
      // Update imageResult here
      var opt = this.options;
      opt.blueprintId = this.blueprint.id;
      // this.$emit("generate", opt);
      this.generating = true;
      //turn off after one second no matter what
      setTimeout(() => {
        this.generating = false;
      }, 2000);

      // append the job,
      const tempId = Date.now(); // temporary ID
      var promptInput = this.promptInput;
      //trim
      promptInput = String(promptInput).trim();
      var newJob = {
        tempId: tempId,
        botId: this.botId,
        blueprintId: this.blueprint.id,
        //  imagePrompt: this.prompt,
        // negativePrompt: this.netativePrompt,
        promptInput: promptInput, //rest is generated based off blueprint template
        imagePrompt: promptInput, //just for diplay purpose...
        images: [],
        status: "pending",
        imgsLoaded: false,
        date: new Date(),
        imageEngine: this.selectedModel,
      };

      // add dummy images, based on the nbImages quantity
      var currentRatio = this.ratio.ratio || 1;
      for (let i = 0; i < this.nbImages; i++) {
        newJob.images.push({
          url: "https://via.placeholder.com/50",
          ratio: currentRatio,
          // width: 50,
          //height: 150,
        });
      }

      this.historyData.unshift(newJob);
      this.$emit("generateStart", newJob);

      window.API.generateBotImageFromBlueprint(this.botId, opt).then((res) => {
        console.log(">>generateBotImagePost res >> ", res);
        // this.redirectSuccess(res.postId);

        this.generating = false;
        //mark the job as completed...
        //newJob.status = "completed";
        // newJob.images = res.images;
        this.$emit("generateDone", tempId, res.post);
        this.replaceHistoryItemByTempId(tempId, res.post);
      });
    },
    setPrompt(newPrompt) {
      //called from parent: ugly...
      this.promptInput = newPrompt;
    },
    replaceHistoryItemByTempId(tempId, newData) {
      const itemIndex = this.historyData.findIndex((item) => item.tempId === tempId);

      console.log(newData, "newData");
      if (itemIndex !== -1) {
        newData.status = "loaded";
        //  this.historyData[itemIndex] = newData;
        this.historyData.splice(itemIndex, 1, newData);
        //    alert(itemIndex);
        /*
        .splice(itemIndex, 1, {
          ...newData,
          status: "loaded",
        });*/
      }
    },
    previewGptPrompt() {
      // Logic for previewing the prompt
    },
    loadHistory() {
      // Logic for loading the history
      if (this.botId) {
        window.API.getBotImageJobs(this.botId).then((res) => {
          console.log(">>getBotImageJobs res >> ", res);
          this.historyData = res;
        });
      } else {
        console.log("43242 NO bots, cont load history of this bot, BUT this could be use....");
      }
    },
    reusePrompt(h) {
      // Logic for reusing the prompt
      this.promptInput = h.imagePrompt;
    },
    async downloadImages(uuu) {
      // Assuming you have an array of image URLs
      /*
      const imageUrls = [
        "https://example.com/image1.jpg",
        "https://example.com/image2.jpg",
        // ... add more URLs as needed
      ];
*/
      const imageUrls = uuu.map((i) => i.url);
      for (let i = 0; i < imageUrls.length; i++) {
        const imageUrl = imageUrls[i];
        const response = await fetch(imageUrl);
        const blob = await response.blob();

        // Extract image file name from URL or create one if not available
        const fileName = imageUrl.split("/").pop() || `image${i + 1}.jpg`;

        const url = URL.createObjectURL(blob);

        // Create an anchor tag dynamically and click it to download the image
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = fileName;

        document.body.appendChild(a);
        a.click();

        // Clean up by revoking the Blob URL and removing the anchor tag
        URL.revokeObjectURL(url);
        document.body.removeChild(a);

        // This delay is to avoid some browsers' pop-up blockers
        await new Promise((resolve) => setTimeout(resolve, 100));
      }
    },
    showImagePopup(src) {
      this.$refs.imagePopup.open(src);
    },
  },
};
</script>

<style scoped>
.boxy-bg {
  box-shadow: 0 0 0 1px rgba(10, 10, 10, 0.1), 0 2px 4px rgba(10, 10, 10, 0.1), 0 8px 24px rgba(10, 10, 10, 0.1);
  border-radius: 10px;
  background: #fff;
  display: block;
  padding-right: 10px;
}

.area {
  background: #fff !important;
  padding: 20px;
  border-radius: 20px;
  border: 0;
}
.area >>> textarea {
  background: #fff !important;
  border-radius: 20px;
}
.stickyForm {
  position: sticky;
  top: 94px;
}
.right {
  display: flex;
  justify-content: flex-end;
}
.advanced {
  margin-top: 40px;
  max-width: 400px;
}
.container.is-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.row {
  display: flex;
  justify-content: space-between;
  justify-content: flex-end;
  flex-direction: row-reverse;
  align-items: flex-end;
}

.generatedPromptPreview {
  font-style: italic;
  opacity: 0.5;
}

/* historyè */

.history .item {
  margin-bottom: 3em;
}
.images {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-content: space-between;
}
.images .image {
  margin-right: 5px;
}

/* img grid */
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px; /* Adjust this value for spacing between images */
  grid-gap: 3px;
}
.grid-container.one {
  grid-template-columns: repeat(1, 1fr);
}
.grid-container.many {
  grid-template-columns: repeat(3, 1fr);
}

.grid-item img {
  width: 100%;
  height: auto;
  display: block;
}
</style>
